import { filterEmptyObjectKeys } from 'utils/order.utils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import 'moment/locale/fr';
import moment from 'moment';
import { camelCase } from 'lodash';
import { useDateFormat } from 'hooks/useDateFormat';
import { SavedCartStatuses } from 'components/shared/SavedCartStatusFilter/SavedCartStatusFilter.types';
import { Button } from 'components/shared';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { Form, DatePicker, Input } from 'antd';
import SavedCartStatusFilter from '../../../../shared/SavedCartStatusFilter';
import { CART_REQUEST_STATUS, ICartRequestParams } from '../../../../../types/cartRequest';
import {
  fetchCartRequests,
  selectCartRequestLimit,
  selectCartRequestStates,
  setFilters,
} from '../../../../../store/cartRequests';
import { CUSTOM_DATE_FORMAT } from 'constants/locale';
import styles from './styles.module.scss';

const CartRequestFilters: FC = () => {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const size = useSelector(selectCartRequestLimit);
  const { t } = useTranslation();
  const states = useSelector(selectCartRequestStates);
  const [status, setStatus] = useState<string | null>();
  const dateFormatShort = useDateFormat('short');

  useEffect(() => {
    status && form.submit();
  }, [form, status]);

  const onFinish = (values: ICartRequestParams, newStatus?: string | null) => {
    const filters: ICartRequestParams = {
      ...values,
      state: newStatus || undefined,
    };

    const cartRequestFilterParameters = filterEmptyObjectKeys<ICartRequestParams>(filters);
    if (cartRequestFilterParameters.dateRange?.length) {
      cartRequestFilterParameters['createdAt[$gte]'] = moment(cartRequestFilterParameters.dateRange[0]._d).format(
        CUSTOM_DATE_FORMAT.mysql.short,
      );
      cartRequestFilterParameters['createdAt[$lte]'] = moment(cartRequestFilterParameters.dateRange[1]._d).format(
        CUSTOM_DATE_FORMAT.mysql.short,
      );
    }
    delete cartRequestFilterParameters.dateRange;

    dispatch(setFilters(cartRequestFilterParameters));
    dispatch(
      fetchCartRequests({
        filters: cartRequestFilterParameters,
        size: size,
        page: 1,
      }),
    );
  };

  const clearInputs = () => {
    setStatus(null);
    onFinish({}, null);
    form.resetFields();
  };

  const handleStatusChange = (newStatus: string) => {
    if (newStatus !== status) {
      setStatus(newStatus);
    } else {
      setStatus(null);
      form.submit();
    }
  };

  const cartRequestStatuses = useMemo(
    () =>
      CART_REQUEST_STATUS.map(
        (cartRequestStatus): SavedCartStatuses => ({
          value: cartRequestStatus,
          label: t(`cartRequests.${camelCase(cartRequestStatus)}`),
          count: states[cartRequestStatus] || 0,
        }),
      ),
    [states, t],
  );

  return (
    <div className={styles.filters}>
      <Form
        form={form}
        layout="horizontal"
        name="filters-form"
        onFinish={(values) => onFinish(values, status)}
        className={styles.form}
        autoComplete="off"
        initialValues={{ remember: false }}
      >
        <div style={{ width: '100%' }}>
          <SavedCartStatusFilter statuses={cartRequestStatuses} value={status} onChange={handleStatusChange} />
        </div>
        <div className={styles.filtersContainer}>
          <div className={styles.inputContainer}>
            <Form.Item name="search" className={styles.input}>
              <Input size="large" placeholder={String(t('cartRequests.nameSurnameCompanyName'))} allowClear />
            </Form.Item>
            <Form.Item name="cartNumber" className={styles.input}>
              <Input size="large" placeholder={String(t('saved_cart.atcCartNumber'))} allowClear />
            </Form.Item>
            <Form.Item name="dateRange" className={styles.input}>
              <RangePicker size="large" locale={locale} format={dateFormatShort} className={styles.dateRange} />
            </Form.Item>
          </div>
          <div className={styles.buttonContainer}>
            <Button className={styles.filterButton} type="primary" htmlType="submit">
              {String(t('order_history.filterResults'))}
            </Button>
            <Button className={styles.filterButton} type="dashed" onClick={clearInputs}>
              {String(t('order_history.deleteFilters'))}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CartRequestFilters;
