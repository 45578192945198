import { useIsMobile } from 'utils/styles.utils';
import { useAppSelector } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, memo, useEffect, useState } from 'react';
import moment from 'moment/moment';
import { Pagination, Table } from 'antd';
import { isFulfilled } from '@reduxjs/toolkit';
import { UnorderedListOutlined } from '@ant-design/icons';
import { Dropdown, Text } from '../../shared';
import { CartRequestActionMenu, CartRequestStatus } from '../../shared';
import { delay } from '../../../utils/loading.utils';
import { nullStringToMinus } from '../../../utils';
import {
  CartRequestItemDetailsProps,
  ICartRequest,
  ICartRequestItem,
  ICartRequestParams,
} from '../../../types/cartRequest';
import {
  isLoadingCartRequests,
  selectCartRequestsList,
  fetchCartRequests,
  resetCartRequestParams,
  selectCartRequestsListById,
  selectCartRequestTotal,
  setPagination,
  selectPageNumber,
} from '../../../store/cartRequests';
import CartRequestFilters from './components/CartRequestFilters';
import styles from './CartRequestsTable.module.scss';

type CartRequestsTableProps = {
  filters: ICartRequestParams;
};

const CartRequestsTable: FC<CartRequestsTableProps> = (props: CartRequestsTableProps) => {
  const { filters } = props;

  const cartRequestsList = useSelector(selectCartRequestsList);
  const total = useSelector(selectCartRequestTotal);
  const isLoading = useSelector(isLoadingCartRequests);
  const [cartRequestDetailsId, setCartRequestDetailsId] = useState<number | null>(null);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const currentPage = useSelector(selectPageNumber);

  useEffect(() => {
    return () => {
      dispatch(resetCartRequestParams);
    };
  }, [dispatch]);

  const isRowExpanded = (id: number) => {
    return Boolean(id && id === cartRequestDetailsId);
  };

  const handleClickDetails = (id: number) => {
    if (id && cartRequestDetailsId === id) {
      setCartRequestDetailsId(null);
    } else {
      setCartRequestDetailsId(id);
    }
  };

  const TABLE_COLUMNS: Array<TableColumnDefinition<ICartRequest>> = [
    {
      title: <span className={styles.col}>{t('cartRequests.quoteNumber')}</span>,
      align: 'left',
      width: '25%',
      dataIndex: undefined,
      render: (data: ICartRequest) => (
        <div className={styles.container}>
          <Text type="subtitle-thin" className={styles.alignLeft}>
            {nullStringToMinus(data.atcCartNumber)}
          </Text>
          {data.assignedTcCode && data.assignedTcName && (
            <Text type="subtitle-thin" className={styles.alignLeft}>
              {t('saved_cart.assignedTo', {
                assignedTcName: data.assignedTcName,
                assignedTcCode: data.assignedTcCode,
              })}
            </Text>
          )}
          <Text type="subtitle-thin" className={styles.alignLeft}>
            {t('cartRequests.cartRequestId')}: <b>{data.id}</b>
          </Text>
        </div>
      ),
    },
    {
      title: <span className={styles.col}>{t('cartRequests.client')}</span>,
      align: 'left',
      width: '20%',
      dataIndex: undefined,
      render: (data: ICartRequest) => {
        const { customer } = data;
        return (
          customer && (
            <>
              <div>
                {customer.firstName} {customer.lastName}
              </div>
              <div>{customer.company}</div>
              <div>{customer.customerIdentifier}</div>
              <div>{customer.email}</div>
            </>
          )
        );
      },
    },
    {
      title: <span className={styles.col}>{t('cartRequests.status')}</span>,
      dataIndex: undefined,
      align: 'center',
      render: ({ status, convertedAt, refusedAt }: ICartRequest) => {
        return <CartRequestStatus status={status} displayDate={convertedAt || refusedAt} />;
      },
    },
    {
      title: <span className={styles.col}>{t('saved_cart.creationDate')}</span>,
      dataIndex: 'submittedAt',
      align: 'center',
      render: (submittedAt) => moment(submittedAt).utc(true).format('lll'),
    },
    {
      title: <span className={styles.col}>{t('cartRequests.actions')}</span>,
      dataIndex: undefined,
      align: 'center',
      render: ({ id, assignedTcCode, status, atcCheckoutCartId }: ICartRequest) => (
        <Dropdown
          className={styles.dropdown}
          loading={delay(isLoading)}
          overlay={() => (
            <CartRequestActionMenu
              id={id}
              assignedTcCode={assignedTcCode}
              status={status}
              atcCheckoutCartId={atcCheckoutCartId}
            />
          )}
          onClick={() => handleClickDetails(id)}
          lableIcon={<UnorderedListOutlined />}
        />
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('cartRequests.pageTitle')}</h3>
      <CartRequestFilters />
      <Table
        columns={TABLE_COLUMNS}
        size={isMobile ? 'small' : 'large'}
        className={styles.container__table}
        rowClassName={styles.row}
        rowKey={(cartRequest) => cartRequest.id}
        loading={isLoading}
        dataSource={cartRequestsList}
        pagination={false}
        expandable={{
          defaultExpandAllRows: true,
          indentSize: 0,
          expandedRowClassName: () => styles.expandableRow,
          expandRowByClick: false,
          expandedRowRender: ({ id }: ICartRequest) => <CartRequestItemDetails cartRequestId={id} />,
          rowExpandable: ({ id }) => isRowExpanded(id),
          expandIcon: () => null,
          expandIconColumnIndex: -1,
          expandedRowKeys: cartRequestsList.map((cartRequest) => cartRequest.id),
        }}
      />
      {total > 0 && (
        <Pagination
          current={currentPage}
          className={styles.container__pagination}
          defaultCurrent={1}
          total={total}
          disabled={false}
          defaultPageSize={30}
          pageSizeOptions={['20', '30', '50', '100']}
          onChange={async (current, pageSize) => {
            dispatch(
              setPagination({
                page: current,
                limit: pageSize,
              }),
            );
            const response = await dispatch(
              fetchCartRequests({
                filters,
                page: current,
                size: pageSize,
              }),
            );

            if (!isFulfilled(response)) {
              dispatch(
                setPagination({
                  page: currentPage,
                  limit: pageSize,
                }),
              );
            }
            return false;
          }}
        />
      )}
    </div>
  );
};

const CartRequestItemDetails: FC<CartRequestItemDetailsProps> = memo(
  ({ cartRequestId }: CartRequestItemDetailsProps) => {
    const cartRequest = useAppSelector(selectCartRequestsListById(cartRequestId));
    const { t } = useTranslation();

    if (!cartRequest) {
      return null;
    }
    const { items } = cartRequest;

    const TABLE_COLUMNS: Array<TableColumnDefinition<ICartRequestItem>> = [
      {
        title: <span className={styles.col}>{t('cartRequests.productInfo')}</span>,
        dataIndex: 'name',
        width: '40%',
        align: 'left',
        render: (name: string) => <Text type="subtitle-thin">{`${name}`}</Text>,
      },
      {
        title: <span className={styles.col}>{t('common.code')}</span>,
        dataIndex: 'sku',
        align: 'center',
        render: (sku: string) => <Text type="subtitle-thin">{`${nullStringToMinus(sku)}`}</Text>,
      },
      {
        title: <span className={styles.col}>{t('common.quantity')}</span>,
        dataIndex: 'quantity',
        align: 'center',
        render: (quantity: number) => <Text type="subtitle-thin">{`${quantity}`}</Text>,
      },
      {
        title: <span className={styles.col}>{t('cartRequests.deliveryPeriod')}</span>,
        dataIndex: 'deliveryPeriod',
        align: 'center',
        render: (deliveryPeriod: string) => <Text type="subtitle-thin">{`${nullStringToMinus(deliveryPeriod)}`}</Text>,
      },
      {
        title: <span className={styles.col}>{t('saved_cart.state')}</span>,
        dataIndex: 'processed',
        align: 'center',
        render: (processed: number) => <Text type="subtitle-thin">{t(`cartRequests.processStatus.${processed}`)}</Text>,
      },
    ];

    return (
      <div className={styles.expandedTable}>
        <Table columns={TABLE_COLUMNS} dataSource={items} pagination={false} />
      </div>
    );
  },
);

export default CartRequestsTable;
