import { ICartRequest, ICartRequestDetail, ICartRequestParams } from 'types/cartRequest';
import { ErrorType } from 'types';
import { RootState } from 'store/rootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { ISavedCartStates } from '../savedCart/types';

export const selectCartRequestsList = (state: RootState): Array<ICartRequest> =>
  Object.values(state.cartRequests.cartRequestsList);

export const selectCartRequestsListById =
  (carRequestId: number) =>
  (state: RootState): ICartRequest => {
    return <ICartRequest>(
      Object.values(state.cartRequests.cartRequestsList).find((cartRequest) => cartRequest.id === carRequestId)
    );
  };

export const selectCartRequestStates = (state: RootState): ISavedCartStates => state.cartRequests.states;

export const isLoadingCartRequests = (state: RootState): boolean => state.cartRequests.isLoading;

export const selectCartRequestLimit = (state: RootState): number => state.cartRequests.limit;
export const selectCartRequestTotal = (state: RootState): number => state.cartRequests.total;
export const selectCartRequestFilters = (state: RootState): ICartRequestParams =>
  state.cartRequests.filters as ICartRequestParams;

export const selectCartRequestDetail = (state: RootState): ICartRequestDetail | null =>
  state.cartRequests.cartRequestDetail;

export const selectCartRequestId = (state: RootState): number | null => {
  if (state.settings.PRODUCT_PAGE === 'customer-quotation' && state.cartRequests.cartRequestDetail?.id) {
    return state.cartRequests.cartRequestDetail?.id;
  }
  return null;
};

export const selectCartRequestItems = createSelector(
  selectCartRequestDetail,
  (cartRequest) => cartRequest?.items || [],
);

export const selectPageNumber = (state: RootState): number => state.cartRequests.page;

export const selectCartRequestError = (state: RootState): ErrorType | null => state.cartRequests.error;
