import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { SavedCartStatusFilterProps } from './SavedCartStatusFilter.types';

const SavedCartStatusFilter: React.FC<SavedCartStatusFilterProps> = (props: SavedCartStatusFilterProps) => {
  const { statuses, value, onChange } = props;

  const handleClick = (val: string) => {
    onChange?.(val);
  };

  const isActive = (status: string) => status === value;

  return (
    <div className={styles.wrapper}>
      <div className={styles.listWrapper}>
        {statuses.map(({ value, label, count }) => (
          <div className={styles.item} key={value}>
            <div className={classNames(styles.label, styles[`label__${value}`])}>{label}</div>
            <div className={styles.countWrapper}>
              <div
                className={classNames(styles.count, {
                  [styles.active]: isActive(value),
                })}
              >
                <div
                  onClick={() => handleClick(value)}
                  className={classNames(styles.countNumber, {
                    [styles.active]: isActive(value),
                  })}
                >
                  {count}
                </div>
              </div>
              <div className={styles.line} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SavedCartStatusFilter;
