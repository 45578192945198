import { useDispatch, useSelector } from 'react-redux';
import { FC, useEffect } from 'react';
import {
  clearCartRequestFilters,
  fetchCartRequests,
  selectCartRequestFilters,
  selectCartRequestLimit,
} from '../../store/cartRequests';
import CartRequestsTable from '../../components/cartRequests';
import styles from './CartRequests.module.scss';

const CartRequests: FC = () => {
  const dispatch = useDispatch();
  const size = useSelector(selectCartRequestLimit);
  const filters = useSelector(selectCartRequestFilters);

  useEffect(() => {
    dispatch(clearCartRequestFilters());

    return () => {
      dispatch(clearCartRequestFilters());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchCartRequests({
        filters,
        page: 1,
        size,
      }),
    );
  }, [dispatch]);

  return (
    <>
      <div className={styles.cartRequests}>
        <CartRequestsTable filters={filters} />
      </div>
    </>
  );
};

export default CartRequests;
