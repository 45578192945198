import { handleCatchError, isFulfilledAction, isPendingAction, isRejectedAction } from 'utils';
import { IStoreShippingItem } from 'types/shippingFees';
import { IOrderItem, IOrderResourceResponse } from 'types/order';
import { ErrorType, IShippingAddresses, ITown } from 'types';
import { RestoreSavedCartStateResponse } from 'store/savedCart/types';
import { restoreSavedCartMatcher } from 'store/savedCart/matcher';
import { RootState } from 'store/rootReducer';
import { deliveryInitialState } from 'store/delivery/state';
import { categorizeShippingItemsNormalizer } from 'store/delivery/normalizers';
import {
  fetchCategorizeShippingItemsStateAdapter,
  fetchShippingAddressesAdapter,
  fetchShippingAddressesStateAdapter,
  fetchCategorizeShippingItemsAdapter,
} from 'store/delivery/adapters';
import { CART_REDUCER_NAME } from 'store/common';
import { ShippingApiService } from 'services';
import groupBy from 'lodash/groupBy';
import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { unwrap } from '../../utils/api.utils';
import { IGroupCustomer } from '../../types/groups';
import { PostcodeApiService } from '../../services/postcodeServiceApi';
import {
  ICategorizeShippingItemsResponse,
  ICreateShippingAddressesParams,
  IDeliveryState,
  IGetShippingAddressByIdParams,
  IGetShippingAddressesParams,
  ISetPointListIdForCategoryParams,
  ShippingDates,
} from './types';

export const DELIVERY_REDUCER_NAME = 'delivery';

const initialState: IDeliveryState = deliveryInitialState;

export const categorizeShippingItems: AsyncThunk<
  ICategorizeShippingItemsResponse,
  Array<IStoreShippingItem>,
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${DELIVERY_REDUCER_NAME}/categorizeOfferByShippingMethod`,
  async (shippingItems, { rejectWithValue, getState }) => {
    const state = getState();
    const {
      cart: { products },
      customers,
    } = state;
    let tags: Array<string>;
    if (!customers.isGroup) {
      tags = customers.customers[customers.selectedCustomer!].tags ?? [];
    } else {
      tags = customers.groupTags[customers.selectedGroupId!] ?? [];
    }
    try {
      return await fetchCategorizeShippingItemsAdapter(
        products,
        fetchCategorizeShippingItemsStateAdapter({ shippingItems, tags, state }),
      );
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const getShippingAddresses: AsyncThunk<
  {
    shippingAddresses: Array<IShippingAddresses>;
    foundTown?: ITown;
  },
  IGetShippingAddressesParams,
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${DELIVERY_REDUCER_NAME}/getShippingAddresses`,
  async ({ userId, token }: IGetShippingAddressesParams, { rejectWithValue }) => {
    try {
      const result = await fetchShippingAddressesAdapter(fetchShippingAddressesStateAdapter({ userId, token }));
      if (result.length === 1) {
        const shippingAddress = result[0];
        const postcodeLabel = shippingAddress.postcodeLabel;
        const city = shippingAddress.townLabel;
        const postalCodes = unwrap(await PostcodeApiService.searchTowns(shippingAddress.postcodeLabel));
        const foundTown = postalCodes.find(
          (town) => town.postcode === postcodeLabel && town.town.toLowerCase() === city.toLowerCase(),
        );
        if (foundTown) {
          return { shippingAddresses: result, foundTown };
        }
      }
      return {
        shippingAddresses: result,
      };
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const getShippingAddressById: AsyncThunk<
  {
    shippingAddresses: Array<IShippingAddresses>;
  },
  IGetShippingAddressByIdParams,
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${DELIVERY_REDUCER_NAME}/getShippingAddressById`,
  async ({ userId, addressId }: IGetShippingAddressByIdParams, { rejectWithValue }) => {
    try {
      const result = unwrap(await ShippingApiService.getShippingAddressesByIds(userId, [addressId]));
      return {
        shippingAddresses: result.data,
      };
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const createShippingAddresses: AsyncThunk<
  IShippingAddresses,
  ICreateShippingAddressesParams,
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${DELIVERY_REDUCER_NAME}/createShippingAddresses`,
  async ({ token, addressParams }: ICreateShippingAddressesParams, { rejectWithValue }) => {
    try {
      return unwrap(await ShippingApiService.createShippingAddresses(addressParams, token));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);
const deliverySlice = createSlice({
  name: DELIVERY_REDUCER_NAME,
  initialState,
  reducers: {
    clearDeliveryState() {
      return { ...initialState };
    },
    clearCustomError(state) {
      state.customError = null;
    },
    setShippingItems(state, { payload }: PayloadAction<Array<IStoreShippingItem>>) {
      state.shippingItems = payload;
    },
    setShippingAddress(state, { payload }: PayloadAction<IShippingAddresses>) {
      state.shippingAddress = payload;
    },
    setShippingAddressForGroup(state, { payload }: PayloadAction<IGroupCustomer>) {
      const { groupAddress } = payload;
      if (groupAddress.length === 1) {
        state.shippingAddress = groupAddress[0];
      }
    },
    setPointListIdForCategory(
      state,
      { payload: { shippingMethodId, pointListId } }: PayloadAction<ISetPointListIdForCategoryParams>,
    ) {
      state.categoryPointList[shippingMethodId] = pointListId;
    },
    updateShippingDetailsShippingItemsDeliveryStartDate(
      state,
      {
        payload: { orderId, deliveryStartDate },
      }: PayloadAction<{ orderId: string } & Pick<ShippingDates, 'deliveryStartDate'>>,
    ) {
      Object.entries(state.shippingDetails.shippingItems).forEach(([key, item]) => {
        if (item.orderId === orderId) {
          state.shippingDetails.shippingItems[key].override.deliveryStartDate = deliveryStartDate;
        }
      });
    },

    updateShippingDetailsShippingItemsDeliveryEndDate(
      state,
      {
        payload: { orderId, deliveryEndDate },
      }: PayloadAction<{ orderId: string } & Pick<ShippingDates, 'deliveryEndDate'>>,
    ) {
      Object.entries(state.shippingDetails.shippingItems).forEach(([key, item]) => {
        if (item.orderId === orderId) {
          state.shippingDetails.shippingItems[key].override.deliveryEndDate = deliveryEndDate;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(categorizeShippingItems.fulfilled, (state, { payload }) => {
        const { shippingItems, shippingFees, shippingCategories, sortedShippingCategories, categoryPointList } =
          categorizeShippingItemsNormalizer(payload, initialState);
        state.customError = payload?.errors?.length > 0 ? groupBy(payload.errors, 'type') : null;
        state.shippingItems = shippingItems;
        state.shippingFees = shippingFees;
        state.shippingCategories = shippingCategories;
        state.sortedShippingCategories = sortedShippingCategories;
        if (categoryPointList) {
          state.categoryPointList = categoryPointList;
        }
      })
      .addCase(
        getShippingAddresses.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            shippingAddresses: Array<IShippingAddresses>;
            foundTown?: ITown;
          }>,
        ) => {
          state.shippingAddresses = payload.shippingAddresses;
          if (payload.shippingAddresses.length === 1) {
            state.shippingAddress = payload.shippingAddresses[0];
          }
        },
      )
      .addCase(
        getShippingAddressById.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            shippingAddresses: Array<IShippingAddresses>;
          }>,
        ) => {
          if (payload.shippingAddresses.length === 1) {
            state.shippingAddress = payload.shippingAddresses[0];
          }
        },
      );
    builder.addMatcher(isPendingAction(`${DELIVERY_REDUCER_NAME}/`), (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addMatcher(isRejectedAction(`${DELIVERY_REDUCER_NAME}`), (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    });
    builder.addMatcher(isFulfilledAction(`${DELIVERY_REDUCER_NAME}/`), (state) => {
      state.error = null;
      state.error = null;
      state.isLoading = false;
    });

    builder.addMatcher(
      restoreSavedCartMatcher(),
      (state, { payload }: PayloadAction<RestoreSavedCartStateResponse>) => {
        Object.assign<IDeliveryState, IDeliveryState>(state, initialState);

        const {
          state: { delivery },
        } = payload;

        Object.assign<IDeliveryState, RestoreSavedCartStateResponse['state']['delivery']>(state, delivery);
      },
    );

    builder.addMatcher(
      isFulfilledAction(`${CART_REDUCER_NAME}/getOrders`),
      (state, { payload: orders }: PayloadAction<Array<IOrderResourceResponse>>) => {
        state.shippingDetails.shippingItems = { ...initialState.shippingDetails.shippingItems };

        const orderItems = orders.reduce<Array<IOrderItem & { orderId: string }>>((acc, order) => {
          order.orderItems.forEach((item) => {
            acc.push({
              orderId: order.id,
              ...item,
            });
          });
          return acc;
        }, []);

        orderItems.forEach(({ orderId, offerId, shippingMethodId: smId, deliveryEndDate, deliveryStartDate }) => {
          const key = `${offerId}-${smId}-${orderId}`;
          if (!state.shippingDetails.shippingItems[key]) {
            state.shippingDetails.shippingItems[key] = {
              orderId,
              offerId: Number(offerId),
              shippingMethodId: Number(smId),
              override: {},
            };
          }

          state.shippingDetails.shippingItems[key] = {
            ...state.shippingDetails.shippingItems[key],
            deliveryEndDate,
            deliveryStartDate,
            override: {},
          };
        });
      },
    );
  },
});

export const {
  setShippingItems,
  setShippingAddress,
  setShippingAddressForGroup,
  setPointListIdForCategory,
  clearDeliveryState,
  updateShippingDetailsShippingItemsDeliveryStartDate,
  updateShippingDetailsShippingItemsDeliveryEndDate,
  clearCustomError,
} = deliverySlice.actions;
export default deliverySlice.reducer;
