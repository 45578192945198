import { SavedCartRestoredState, SavedCartStatusEnum } from 'store/savedCart/types';
import { CartRequestStateNormalizerReturnType } from 'store/cartRequests/types';
import { IShippingAddresses } from './town';
import { IObjectKeys } from './order';
import { ICustomer } from './customers';

export type ICartRequest = {
  id: number;
  uuid: string;
  userId: number;
  countryCode: string;
  locale: string;
  status: typeof SavedCartStatusEnum[keyof typeof SavedCartStatusEnum];
  comment: string | null;
  addressId: number;
  customerId: number;
  assignedTcCode: string | null;
  assignedTcName: string | null;
  submittedAt: string;
  createdAt: string;
  updatedAt: string;
  convertedAt: string;
  refusedAt: string;
  customer: ICustomer;
  delivery: IShippingAddresses;
  items: Array<ICartRequestItem>;
  atcCheckoutCartId: string | null;
  atcCartNumber: string | null;
};

export type ICartRequestDetailCart = {
  id: string;
  cart: SavedCartRestoredState['cart'];
};

export type ICartRequestDetail = ICartRequest & {
  customer_whitelist: {
    email: string;
  };
  items: Array<ICartRequestItem>;
  cart: ICartRequestDetailCart | null;
};

export type ICartRequestItemAttributes = {
  id: number;
  cartRequestId: number;
  businessUnitId: Nullable<number>;
  processed: number;
  unitId: number;
  ulid: string;
  name: string;
  sku: Nullable<string>;
  quantity: number;
  brand: Nullable<string>;
  model: Nullable<string>;
  deliveryPeriod: Nullable<string>;
};

export type ICartRequestItemPatchAttributes = Omit<ICartRequestItemAttributes, 'id'>;

export type ICartRequestItem = ICartRequestItemAttributes & {
  unit?: {
    id: number;
    name: string;
  };
};

export interface ICartRequestParams extends IObjectKeys {
  dateRange?: Array<{ _d: string }>;
  customerIdentifier?: string;
  state?: string;
  'createdAt[$gte]'?: string;
  'createdAt[$lte]'?: string;
  cartNumber?: string;
  isAssigned?: boolean;
}

export type CartRequestItemDetailsProps = {
  cartRequestId: number;
};

export const CART_REQUEST_STATUS = [
  SavedCartStatusEnum.PENDING,
  SavedCartStatusEnum.IN_PROGRESS,
  SavedCartStatusEnum.SENT_TO_CUSTOMER,
  SavedCartStatusEnum.CONVERTED,
  SavedCartStatusEnum.REFUSED,
];

export type FetchCartRequestAndCreateStateResponse = {
  cartRequestDetail: ICartRequestDetail;
  cartRequestState: CartRequestStateNormalizerReturnType;
};
