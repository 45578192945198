import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useToken } from '../cookies';
import { getLoginPath, getOrderPath, getOrderRoute, OrderRoute } from 'constants/routes';
import { useAuth } from './useAuth';

type ILogoutHook = {
  logout: () => void;
  loginRedirectAfterLogout: () => void;
};

type IRoutingReason = {
  reason: string;
  from: {
    pathname: string;
  };
};

export const useLogout = (): ILogoutHook => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout: adminLogout } = useAuth();
  const { removeToken, token } = useToken();

  const logout = async () => {
    if (token) {
      try {
        await adminLogout(token);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    removeToken();
    navigate(getLoginPath(), {
      state: {
        from: location.pathname,
        reason: 'expired',
      },
    });
  };

  const loginRedirectAfterLogout = () => {
    const { reason, from } = (location.state || {}) as IRoutingReason;
    if (
      reason === 'expired' &&
      from?.pathname &&
      from?.pathname !== getLoginPath() &&
      !from?.pathname?.includes(getOrderPath(OrderRoute.ORDER))
    ) {
      navigate(from.pathname);
    } else {
      navigate(getOrderPath(OrderRoute.CUSTOMER));
    }
  };

  return { logout, loginRedirectAfterLogout };
};
