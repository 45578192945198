import { delay } from 'utils/loading.utils';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  deleteSavedCart,
  getSavedCarts,
  selectCurrentPage,
  selectFilters,
  selectLimitSavedCart,
} from 'store/savedCart';
import { FC, useState } from 'react';
import { Modal, Text, Button } from 'components/shared';
import { DeleteConfirmationModalProps } from 'components/savedCart/SavedCartTable/components/DeleteConfirmationModal/types';
import { Col, Row } from 'antd';
import { isFulfilled } from '@reduxjs/toolkit';
import styles from './styles.module.scss';

const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({
  visible,
  onClose,
  cartId,
}: DeleteConfirmationModalProps) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector(selectCurrentPage);
  const currentPageLimit = useAppSelector(selectLimitSavedCart);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const selectedFilters = useAppSelector(selectFilters);

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    const response = await dispatch(deleteSavedCart({ id: cartId }));
    if (isFulfilled(response)) {
      await dispatch(
        getSavedCarts({
          skip: currentPage,
          limit: currentPageLimit,
          filters: selectedFilters,
        }),
      );
    }
    setIsLoadingDelete(false);
    onClose();
  };

  return (
    <Modal width="460px" className={styles.modal} open={visible} onCancel={onClose}>
      <Row>
        <Col span={22}>
          <Text type="title">Etes-vous sûr de vouloir archiver le panier ?</Text>
        </Col>
        <Col span={22}>
          <Row className={styles.actions} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={11}>
              <Button loading={delay(isLoadingDelete)} onClick={handleDelete}>
                Confirmer
              </Button>
            </Col>
            <Col span={11}>
              <Button onClick={onClose} type="dashed">
                Annuler
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={2} />
      </Row>
    </Modal>
  );
};

export default DeleteConfirmationModal;
