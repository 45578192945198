/**
 * Returns a string with first letter of the given string capitalized
 * @param text
 */
export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const nullStringToMinus = (text: string | null | undefined): string => {
  return text ? text : '-';
};

export const attachRegExToRoutePath = (path: string): string => {
  // Define the regex pattern to replace the optional segment after the first slash
  const regexPattern = '(?:/[a-zA-Z0-9-]+)?'; // Updated pattern to include an optional slash

  // Find the index of the first slash after "/order"
  const firstSlash = path.indexOf('/', 1); // Find the first slash after the initial slash

  // If the first slash is found, construct the new path with the regex pattern
  if (firstSlash !== -1) {
    // Insert the regex pattern immediately after the first part of the path
    return `${path.slice(0, firstSlash)}${regexPattern}${path.slice(firstSlash)}`;
  }

  // If the path does not contain the expected structure, return the original path
  return path;
};
