import { useParams } from 'react-router-dom';
import { FC } from 'react';
import { ValidatedContainer } from 'components/validated';
import { ValidatedType } from './Validated.types';

const Validated: FC = () => {
  const { type } = useParams();

  return <ValidatedContainer type={type as ValidatedType} />;
};

export default Validated;
