import { createCartProduct, getDefaultParsedProdcutItem, getProductOfferByQuantity } from 'utils';
import { useAppSelector } from 'store/store';
import {
  selectReplacementModalProductIndex,
  selectReplacementModalQuantity,
  setReplacementModalDisplayAllOffers,
  setReplacementModalProductIndex,
  setReplacementModalQuantity,
  setReplacementModalSku,
  setReplacementModalVisible,
  setReplacementPrice,
} from 'store/products';
import { sendMessage } from 'store/messages';
import {
  selectIsGroup,
  selectSelectedCustomerObject,
  selectSelectedGroupCustomers,
  selectSelectedGroupsCustomerIds,
  selectSelectedGroupsCustomerIdentifiers,
} from 'store/customers';
import { getLastUnitPricesBySku, replaceProduct, selectCartProducts, selectParsedReplacementProduct } from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, memo, useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from 'components/shared';
import { Popconfirm, Tooltip } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import styles from '../../ProductTable/components/ProductAmount/styles.module.scss';
import { selectUserTcCode } from '../../../../store/user';
import { IParsedProductItem } from '../../../../store/products/types';

type ReplacementProductAmountProps = {
  offerId: number;
  product: IParsedProductItem;
};

const ReplacementProductAmount: FC<ReplacementProductAmountProps> = memo((props: ReplacementProductAmountProps) => {
  const { t } = useTranslation();
  const { offerId, product } = props;
  const { quantityIncrement, stock, price } = getDefaultParsedProdcutItem(product);
  const [initialPrice] = useState(price);
  const { customerIdentifier, user } = useSelector(selectSelectedCustomerObject) ?? {};
  const tcCode = useSelector(selectUserTcCode);
  const quantity = useSelector(selectReplacementModalQuantity);
  const productIndex = useSelector(selectReplacementModalProductIndex);
  const customers = useSelector(selectSelectedGroupCustomers);
  const groupLeaderIdentifier = customers.find((c) => c.userId === c.leaderId)?.customerIdentifier;

  const parsedSelector = useMemo(() => {
    return selectParsedReplacementProduct(offerId, quantity, product);
  }, [offerId, product, quantity]);

  const parsedProduct = useAppSelector(parsedSelector);
  const cartProducts = useSelector(selectCartProducts);
  const isGroup = useSelector(selectIsGroup);
  const selectedGroupCustomerIds = useSelector(selectSelectedGroupsCustomerIds);
  const selectedGroupsCustomerIdentifiers = useSelector(selectSelectedGroupsCustomerIdentifiers);
  const dispatch = useDispatch();
  const userHasValidCertificate = useMemo(() => {
    if (isGroup) {
      return true;
    }

    const date = moment(user?.certiphytoExpiredDate);
    if (!date || !date.isValid() || date.isBefore(moment(), 'day')) {
      return false;
    }

    return true;
  }, [isGroup, user?.certiphytoExpiredDate]);

  const allUsersHaveValidCertificate = useMemo(() => {
    if (!isGroup) {
      return true;
    }

    const validity = customers.filter((customer) => {
      const date = moment(customer?.user?.certiphytoExpiredDate);
      return date && date.isValid() && date.isAfter(moment(), 'day');
    });

    return validity.length === customers.length;
  }, [customers, isGroup]);

  useEffect(() => {
    checkProductOffer(quantity);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  const checkProductOffer = (qty: number) => {
    const newDiscount = getProductOfferByQuantity(qty, product);
    if (newDiscount && product.offerId) {
      dispatch(
        setReplacementPrice({
          offerId: product.offerId,
          price: newDiscount.DiscountedPrice,
        }),
      );
    }
  };

  const cartProductToReplace = useMemo(() => {
    if (productIndex !== null && productIndex > -1) {
      return cartProducts[productIndex];
    }
  }, [cartProducts, productIndex]);

  const onFinish = () => {
    dispatch(setReplacementModalSku(null));
    dispatch(setReplacementModalVisible(false));
    dispatch(setReplacementModalQuantity(0));
    dispatch(setReplacementModalProductIndex(null));
    dispatch(setReplacementModalDisplayAllOffers(true));
  };

  const onReplaceProduct = (keepPrice: boolean) => {
    if (!cartProductToReplace || productIndex === null) {
      return;
    }

    if (!offerId) {
      return;
    }

    const updatedStock = stock - quantity * quantityIncrement;

    if (offerId && updatedStock < 0) {
      dispatch(
        sendMessage({
          message: t('common.noStockMessage'),
          type: 'warning',
        }),
      );
    }

    if ((!userHasValidCertificate || !allUsersHaveValidCertificate) && (product?.flags || [])?.includes('certiphyto')) {
      dispatch(
        sendMessage({
          message: t('common.noCertificateMessage'),
          type: 'warning',
        }),
      );
    }

    if (offerId && parsedProduct) {
      const payload = createCartProduct(
        isGroup,
        selectedGroupCustomerIds,
        selectedGroupsCustomerIdentifiers,
        parsedProduct,
        product,
        quantity,
      );

      if (customerIdentifier && tcCode) {
        dispatch(
          getLastUnitPricesBySku({
            sku: product.sku,
            customerIdentifier,
            tcCode,
          }),
        );
      } else if (groupLeaderIdentifier && tcCode) {
        dispatch(
          getLastUnitPricesBySku({
            sku: product.sku,
            customerIdentifier: groupLeaderIdentifier,
            tcCode,
          }),
        );
      }

      payload.match({
        Some: ({ cartProduct }) => {
          if (!keepPrice) {
            dispatch(
              replaceProduct({
                replacement: {
                  ...cartProduct,
                  offerPrice: price?.type === 1 ? price.initialPrice! : cartProduct.offerPrice,
                  discountOffers: [...(cartProduct.discountOffers ?? [])].sort(
                    (a, b) => b.MinimumQuantity - a.MinimumQuantity,
                  ),
                  publicComment: cartProductToReplace.publicComment,
                },
                productIndex,
              }),
            );
          } else {
            dispatch(
              replaceProduct({
                replacement: {
                  ...cartProduct,
                  discountOffers: [...(cartProduct.discountOffers ?? [])].sort(
                    (a, b) => b.MinimumQuantity - a.MinimumQuantity,
                  ),
                  enforcedPrice: true,
                  offerPrice: cartProductToReplace.offerPrice,
                  publicComment: cartProductToReplace.publicComment,
                },
                productIndex,
              }),
            );
          }

          onFinish();
          return;
        },
        Error: ({ message }) => {
          dispatch(
            sendMessage({
              message,
              type: 'warning',
            }),
          );
        },
      });
    }
  };

  const productToReplaceHasOffer = Boolean(cartProductToReplace?.offer);

  const disableButton = !offerId || cartProductToReplace?.offerId === offerId;

  return (
    <div className={`${styles.amount} ${styles.replacement}`}>
      <Tooltip title={t('cart.replace')}>
        {productToReplaceHasOffer && (
          <Popconfirm
            title={t('modals.confirmationPopupKeepPriceTitle')}
            placement="leftTop"
            okText={t('modals.confirmationModalAcceptButton')}
            cancelText={t('modals.confirmationModalDeclineButton')}
            onCancel={() => onReplaceProduct(false)}
            onConfirm={() => onReplaceProduct(true)}
          >
            <Button icon={<SwapOutlined />} disabled={disableButton} className={styles.button} />
          </Popconfirm>
        )}
        {!productToReplaceHasOffer && (
          <Button
            icon={<SwapOutlined />}
            onClick={() => onReplaceProduct(false)}
            disabled={disableButton}
            className={styles.button}
          />
        )}
      </Tooltip>
    </div>
  );
});

export default ReplacementProductAmount;
