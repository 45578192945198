import { extensionsToUploadAccept, mimeTypeToExtensions } from 'utils';
import { ErrorDetailsAtcCheckoutCartAttachment, ErrorType } from 'types';
import { useAppDispatch, useAppSelector } from 'store/store';
import { MessageType } from 'store/messages/type';
import { sendMessage } from 'store/messages';
import { addCartAttachment, selectCartId } from 'store/cart';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useRef, useState } from 'react';
import env from 'config';
import { Button as OwnButton } from 'components/shared';
import { RcFile } from 'antd/lib/upload/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import { Upload, Button, Tooltip } from 'antd';
import { isFulfilled } from '@reduxjs/toolkit';
import { FileAddOutlined, FileOutlined } from '@ant-design/icons';
import {
  CartAttachmentsRequestOptions,
  CartAttachmentsRequestOptionsSanitize,
  CartAttachmentUploadProps,
  UploadStatusType,
} from './types';
import styles from './CartAttachmentUpload.module.scss';
const CartAttachmentUpload: React.FC<CartAttachmentUploadProps> = (props: CartAttachmentUploadProps) => {
  const { productIndex, product, addMore = false, text } = props;
  const { cartProductId, productId, variantId, offerId, attachments } = product;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const extensionList = env('cartItemAttachmentAllowedExtensions');
  const attachmentExtensions = extensionsToUploadAccept(extensionList);
  const attachmentLimit = env('cartItemAttachmentsLimit');
  const attachmentMaxFilesize = env('cartItemAttachmentMaxSize');

  const uploadStatus = useRef<UploadStatusType>({ error: [], done: [] });
  const cartId = useAppSelector(selectCartId);

  const getErrorMessage = useCallback(
    (
      errorType: ErrorDetailsAtcCheckoutCartAttachment['className'] | undefined,
      errorMessage: string,
      _count: number,
    ): MessageType => {
      const error: MessageType = {
        type: 'error',
        message: '',
      };

      switch (errorType) {
        case 'unsupported-file-extension':
          error.message = t('errors.cartAttachmentUnsupportedFileFormat');
          error.type = 'warning';
          break;
        case 'upload-limit-exceeded':
          error.message = t('errors.cartAttachmentExceededLimit');
          error.type = 'warning';
          break;
        default:
          error.message = errorMessage;
          break;
      }

      return error;
    },
    [t],
  );

  const beforeUpload = (file: RcFile, _fileList: RcFile[]): boolean => {
    const extensions = mimeTypeToExtensions(file.type);
    const isExtensionValid = !!extensions?.find((ext) => extensionList.includes(ext));
    const isValidFilesize = file.size < attachmentMaxFilesize;

    if (!isExtensionValid) {
      dispatch(
        sendMessage({
          type: 'error',
          message: t('errors.cartAttachmentUnsupportedFileFormat'),
        }),
      );
    }

    if (!isValidFilesize) {
      dispatch(
        sendMessage({
          type: 'error',
          message: t('errors.cartAttachmentExceededLimit'),
        }),
      );
    }

    return isExtensionValid && isValidFilesize;
  };

  const uploadRequest = useCallback(
    async (options: CartAttachmentsRequestOptions) => {
      const { onSuccess, onError, file } = options as CartAttachmentsRequestOptionsSanitize;

      try {
        if (!cartId) {
          throw new Error('Invalid cart id.');
        }

        const response = await dispatch(
          addCartAttachment({
            cartProductId,
            productIndex,
            cartId,
            productId,
            variantId,
            offerId,
            file,
          }),
        );

        if (!isFulfilled(response)) {
          throw response.payload;
        }

        onSuccess?.();
      } catch (err) {
        dispatch(
          sendMessage({
            message: t(
              (err as { code: string })?.code ? `errors.${(err as { code: string }).code}` : 'errors.invalidFileUpload',
            ),
            code: Number((err as { code: string })?.code),
            type: 'error',
          }),
        );
        onError?.();
      }
    },
    [cartId, cartProductId, dispatch, offerId, productId, productIndex, t, variantId],
  );

  const handleUploadChange = useCallback(
    (info: UploadChangeParam) => {
      const { file, fileList } = info;
      if (file.status && ['uploading'].includes(file.status)) {
        setLoading(true);
      }

      for (let i = fileList.length - 1; i >= 0; i--) {
        const { status } = fileList[i];
        const tmp = fileList[i];

        if (status !== 'uploading') {
          fileList.splice(i, 1);
        }

        if (status === 'done') {
          uploadStatus.current.done.push(tmp);
        }

        if (status === 'error') {
          uploadStatus.current.error.push(tmp);
        }
      }

      const isUploadingDone = fileList.length === 0;

      if (!isUploadingDone) {
        setLoading(true);
      } else {
        const { done, error } = uploadStatus.current;
        setLoading(false);

        if (done.length > 0) {
          dispatch(
            sendMessage({
              message: t('cart.cartAttachmentUploadSuccess', { count: done.length }),
              type: 'success',
            }),
          );
        }

        if (error.length > 0) {
          const groupErrors = error.reduce<
            Array<{ count: number; error: ErrorType<ErrorDetailsAtcCheckoutCartAttachment> }>
          >((acc, fi) => {
            const err: ErrorType<ErrorDetailsAtcCheckoutCartAttachment> = fi.error;

            const foundError = acc.find((item) => item.error.details?.className === err.details?.className);
            if (foundError) {
              foundError.count++;
            } else {
              acc.push({ count: 1, error: err });
            }

            return acc;
          }, []);

          groupErrors.forEach(({ count, error: err }) => {
            const message = getErrorMessage(err.details?.className, err.message, count);
            dispatch(sendMessage(message));
          });
        }

        uploadStatus.current = { error: [], done: [] };
      }
    },
    [dispatch, getErrorMessage, setLoading, t],
  );

  return (
    <div className={styles.wrapper}>
      <Upload
        className={styles.upload}
        name="cartAttachments"
        customRequest={uploadRequest}
        accept={attachmentExtensions}
        beforeUpload={beforeUpload}
        showUploadList={false}
        multiple={true}
        maxCount={attachmentLimit}
        onChange={handleUploadChange}
      >
        {text && <OwnButton type="primary">{text}</OwnButton>}
        {!text && (
          <Tooltip title={t('common.uploadsTooltip')}>
            <Button
              icon={addMore ? <FileAddOutlined /> : <FileOutlined />}
              size="middle"
              className={styles.button}
              disabled={attachments.length >= attachmentLimit || loading}
              loading={loading}
              type="text"
              shape="circle"
            />
          </Tooltip>
        )}
      </Upload>
    </div>
  );
};

export default CartAttachmentUpload;
