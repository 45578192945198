import { downloadPdf, IPDFProps } from 'utils/pdf/utils';
import { delay } from 'utils/loading.utils';
import { selectUserTcCode } from 'store/user';
import { useAppDispatch, useAppSelector } from 'store/store';
import { SavedCartStatusEnum, SavedCartUpdateData } from 'store/savedCart/types';
import {
  assignToCart,
  clearProductError,
  clearSavedCartState,
  getSavedCarts,
  selectCurrentPage,
  selectFilters,
  selectLimitSavedCart,
  setPage,
  updateSavedCart,
} from 'store/savedCart';
import { exportSavedCartPDF } from 'store/cart';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FC, memo, useMemo, useState } from 'react';
import { usePermissions } from 'hooks';
import env from 'config';
import { Dropdown } from 'components/shared';
import { AssignmentModal, DeleteConfirmationModal } from 'components/savedCart/SavedCartTable/components';
import { ItemType } from 'antd/es/menu/interface';
import { Menu } from 'antd';
import { isFulfilled } from '@reduxjs/toolkit';
import {
  CopyOutlined,
  CustomerServiceOutlined,
  FilePdfOutlined,
  FileSyncOutlined,
  FileZipOutlined,
  ReloadOutlined,
  UnorderedListOutlined,
  UserDeleteOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import CloneCartConfirmationModal from '../../CloneCartConfirmationModal';
import { getOrderPath, OrderRoute } from '../../../../../../constants/routes';
import { ActionsColumnProps } from './types';
import styles from './styles.module.scss';

const ActionsColumn: FC<ActionsColumnProps> = memo(
  ({
    cartId,
    onClickDetails,
    isArchived,
    saveCartPdfId,
    createdAt,
    convertedAt,
    customer,
    assignedTcCode,
  }: ActionsColumnProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isAssignmentModalVisible, setIsAssignmentModalVisible] = useState(false);
    const [isCloneCartModalVisible, setIsCloneCartModalVisible] = useState(false);
    const [isLoading, setIsLoaded] = useState(false);
    const { t } = useTranslation();
    const selectedFilters = useAppSelector(selectFilters);
    const currentPage = useAppSelector(selectCurrentPage);
    const currentPageLimit = useAppSelector(selectLimitSavedCart);
    const currentTcCode = useAppSelector(selectUserTcCode)!;
    const isAssignedToSelf = String(assignedTcCode) === String(currentTcCode);
    const isAssigned = assignedTcCode === null || String(assignedTcCode).trim().length === 0;

    const { allowed: allowedToRestore, sendNotice: sendNoticeRestore } = usePermissions(
      ['QUOTE_RESTORE'],
      false,
      false,
    );
    const { allowed: allowedToArchive, sendNotice: sendNoticeArchive } = usePermissions(
      ['QUOTE_ARCHIVE'],
      false,
      false,
    );

    const { allowed: allowedToListTC } = usePermissions(['QUOTE_CAN_LIST_TC_QUOTE'], false, false);
    const allowedToClone = env('allowCloneCart');

    const assignToMe = () => async () => {
      await patchSavedCart(currentTcCode);
    };

    const unassign = () => async () => {
      await patchSavedCart('null');
    };

    const patchSavedCart = async (tcCode: string) => {
      await dispatch(assignToCart({ cartId, tcCode }));
    };

    const handleRestoreSavedCart = async () => {
      if (!allowedToRestore) {
        sendNoticeRestore(t('saved_cart.restoreNotAllowed') as string);
        return;
      }
      return navigate(getOrderPath(OrderRoute.PRODUCT, cartId));
    };

    const disableRestore = useMemo(() => {
      if (currentTcCode?.toString().toLowerCase() !== assignedTcCode?.toString().toLowerCase()) {
        return !allowedToListTC;
      } else {
        return !allowedToRestore;
      }
    }, [currentTcCode, assignedTcCode, allowedToListTC, allowedToRestore]);

    const handleDeleteConfirmation = () => {
      if (!allowedToArchive) {
        sendNoticeArchive(t('saved_cart.archiveNotAllowed') as string);
        return;
      }
      setIsDeleteModalVisible(true);
    };

    const handleUnarchiveConfirmation = async () => {
      if (isFulfilled(await dispatch(updateSavedCart({ id: cartId, data: { archivedAt: null } })))) {
        await dispatch(
          getSavedCarts({
            skip: currentPage,
            limit: currentPageLimit,
            filters: selectedFilters,
          }),
        );
      }
    };

    const refreshCarts = () => {
      dispatch(setPage(1));
      dispatch(
        getSavedCarts({
          skip: 0,
          limit: currentPageLimit,
          filters: selectedFilters,
        }),
      );
    };

    const handleDownloadPdf = async (props: IPDFProps): Promise<void> => {
      const { pdfId } = props;
      setIsLoaded(true);
      const response = await dispatch(exportSavedCartPDF({ saveCartPdfId: pdfId }));
      setIsLoaded(false);

      if (isFulfilled(response)) {
        downloadPdf({
          ...props,
          body: response.payload,
        } as IPDFProps);
      }
    };
    const getDropdownMenuItems = (): ItemType[] => {
      const dropdownMenuItems: ItemType[] = [];

      if (allowedToListTC && !convertedAt) {
        dropdownMenuItems.push({
          icon: <CustomerServiceOutlined />,
          onClick: () => setIsAssignmentModalVisible(true),
          label: `${t('saved_cart.assign')}`,
        } as unknown as ItemType);
      }

      if (isAssignedToSelf && status !== SavedCartStatusEnum.CONVERTED) {
        dropdownMenuItems.push({
          icon: <UserDeleteOutlined />,
          onClick: unassign(),
          label: t(`cartRequests.unassigned`),
        } as unknown as ItemType);
      }

      if (isAssigned && status !== SavedCartStatusEnum.CONVERTED) {
        dropdownMenuItems.push({
          icon: <UserAddOutlined />,
          onClick: assignToMe(),
          label: t(`cartRequests.assigned`),
        } as unknown as ItemType);
      }

      if (env('enableExportPdf') && saveCartPdfId && saveCartPdfId > 0) {
        dropdownMenuItems.push({
          icon: <FilePdfOutlined />,
          onClick: () =>
            handleDownloadPdf({
              pdfId: saveCartPdfId,
              cartId: cartId,
              customer: customer,
              createdAt: createdAt,
            } as IPDFProps),
          label: `${t('saved_cart.downloadPdf')}`,
        } as unknown as ItemType);
      }

      dropdownMenuItems.push({
        icon: <ReloadOutlined />,
        onClick: () => handleRestoreSavedCart(),
        label: `${t('saved_cart.restore')}`,
        disabled: disableRestore,
      } as unknown as ItemType);

      if (allowedToClone) {
        dropdownMenuItems.push({
          icon: <CopyOutlined />,
          onClick: () => setIsCloneCartModalVisible(true),
          label: t('saved_cart.clone'),
        } as unknown as ItemType);
      }

      if (isArchived) {
        dropdownMenuItems.push({
          icon: <FileSyncOutlined />,
          onClick: () => handleUnarchiveConfirmation(),
          label: `${t('saved_cart.unarchive')}`,
        } as unknown as ItemType);
      } else {
        dropdownMenuItems.push({
          icon: <FileZipOutlined />,
          onClick: () => handleDeleteConfirmation(),
          label: `${t('saved_cart.archive')}`,
          disabled: !allowedToArchive,
        } as unknown as ItemType);
      }
      return dropdownMenuItems;
    };

    return (
      <>
        <Dropdown
          className={styles.dropdown}
          loading={delay(isLoading)}
          overlay={() => <Menu items={getDropdownMenuItems()} />}
          onClick={() => onClickDetails?.(cartId)}
          lableIcon={<UnorderedListOutlined />}
        />
        <DeleteConfirmationModal
          visible={isDeleteModalVisible}
          cartId={cartId}
          onClose={() => {
            setIsDeleteModalVisible(false);
          }}
        />
        <CloneCartConfirmationModal
          visible={isCloneCartModalVisible}
          cartId={cartId}
          onSuccess={refreshCarts}
          onClose={() => setIsCloneCartModalVisible(false)}
        />
        {allowedToListTC && (
          <AssignmentModal
            cartId={cartId}
            visible={isAssignmentModalVisible}
            onClose={() => setIsAssignmentModalVisible(false)}
          />
        )}
      </>
    );
  },
);

export default ActionsColumn;
